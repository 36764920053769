class LinkImageData {
	id: number;
	image: string;
	imageAlt: string;
	link: string;

	constructor(id: number, image: string, imageAlt: string, link: string) {
		this.id = id;
		this.image = image;
		this.imageAlt = imageAlt;
		this.link = link;
	}
}

export { LinkImageData }