import { createApp } from 'vue'
import App from './App.vue'

// Add the CSS.
import "./assets/global.css"
import "github-markdown-css/github-markdown-dark.css";
import "./assets/tailwind.css"

// Create the app.
const app = createApp(App);

// Setup router.
import router from './router'
app.use(router)

// Add analytics
import VueGtag from "vue-gtag";
app.use(VueGtag, {
	config: { 
		id: "G-7SB63NY3K7",
		params: {
			anonymize_ip: true
		}
	}
});

// Add Markdown
import Markdown from "vue3-markdown-it";
import "highlight.js/styles/monokai.css";

app.use(Markdown);

// Add ScrollTrigger into gsap.
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Setup gallery.
import vue3PhotoPreview from 'vue3-photo-preview';
app.use(vue3PhotoPreview);

// Start the app.
app.mount('#app')
